import * as React from "react"
import { useStaticQuery, graphql } from 'gatsby'
import { ReactComponent as Weixin } from '../images/weixin.svg'
import { ReactComponent as Weibo } from "../images/weibo.svg"
import { ReactComponent as Linkedin } from "../images/linkedin.svg"
import { ReactComponent as Bilibili } from "../images/bilibili.svg"
import { ReactComponent as Toktik } from "../images/toktik.svg"
const Footer = (props) => {
    const pageQuery = useStaticQuery(graphql`
    query {
        allStrapiGlobal {
            edges {
                node {
                    weixin,
                    weibo,
                    bilibili,
                    toktik,
                    linkedin,
                    salePhone,
                    centralPhone,
                    infoEmail,
                    recordNumber {
                        name
                        url
                    }
                }
            }
        }
        allStrapiProductCategories {
            edges {
                node {
                  title,
                  url
                }
            }
        }
    }`)
    const node = pageQuery.allStrapiGlobal.edges[0].node
    const nodeLi = pageQuery.allStrapiProductCategories.edges
    return (
        props.visible?
        <footer className="relative z-[2] w-full bg-black pt-[60px] pb-[50px] text-[#aaa] text-sm mt-auto">
            <div className="w-[1252px] px-4 mx-auto relative xl:w-full">
                <div className="flex items-start justify-between xl:flex-col xl:mb-4 xl:pb-4 xl:border-white xl:border-b">
                    <p className="text-white xl:mb-10">速通智能控制科技（无锡）有限公司</p>
                    <div className="xl:w-full">
                        <div className="flex items-start justify-between pb-[30px] xl:flex-col xl:pb-4">
                            <div className="xl:mb-4 xl:pb-4 xl:border-[#d8d8d8] xl:border-b xl:w-full">
                                <div className="font-semibold pb-2.5">产品系列</div>
                                <ul>
                                    {
                                        nodeLi.map((n,i)=>{
                                            return <li key={i} className="py-[5px]"><a href={n.node.url} className="hover:text-white">{n.node.title}</a></li>
                                        })
                                    }
                                </ul>
                            </div>
                            <div className="ml-[76px] xl:ml-0 xl:mb-4 xl:pb-4 xl:border-[#d8d8d8] xl:border-b xl:w-full">
                                <div className="font-semibold pb-2.5">速通品牌</div>
                                <ul>
                                    <li className="py-[5px]"><a className="hover:text-white" href="/tech">核心技术</a></li>
                                    <li className="py-[5px]"><a className="hover:text-white" href="/case/biomedical">案例应用</a></li>
                                    <li className="py-[5px]"><a className="hover:text-white" href="/media">媒体中心</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="pb-10 xl:pb-6">
                            <div className="font-semibold pb-2.5">取得联系</div>
                            <p>无锡市<br/>惠山经济开发区<br/>阳山配套区陆通路15号</p>
                        </div>
                        <div className="pb-10 xl:pb-6">
                            <p>销售  {node.salePhone}</p>
                            <p>总机  {node.centralPhone}</p>
                            <p><a href={`mailto:${node.infoEmail}`} className="hover:text-white underline">{node.infoEmail}</a></p>
                            <ul className="flex items-center pt-10 xl:pt-6">
                                <li className="mr-2 xl:mr-4 hover:text-white"><a href={node.weixin}><Weixin width={26}/></a></li>
                                <li className="mr-2 xl:mr-4 hover:text-white"><a href={node.weibo}><Weibo width={26}/></a></li>
                                <li className="mr-2 xl:mr-4 hover:text-white"><a href={node.linkedin}><Linkedin width={26}/></a></li>
                                <li className="mr-2 xl:mr-4 hover:text-white"><a href={node.bilibili}><Bilibili width={24}/></a></li>
                                <li className="mr-2 xl:mr-4 hover:text-white"><a href={node.toktik}><Toktik width={18}/></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                
                <div className="flex items-center justify-center text-white xl:items-start xl:flex-col">
                    <p className="xl:mb-3">Sotto © {new Date().getFullYear()}   <a href={node.recordNumber.url}>{node.recordNumber.name}</a>  </p>
                    <ul className="flex items-center ml-[36px] xl:ml-0 xl:mb-3">
                        <li className="pr-3"><a href="/privacy">隐私与法律</a></li>
                        <li className="pr-3"><a href="/contact">联系我们</a></li>
                        <li className="pr-3"><a href="/job">工作机会</a></li>
                        <li className="pr-3"><a href="/sale-service">售后服务</a></li>
                    </ul>
                </div>
                <a href="https://gemfisher.com" className="absolute right-4 bottom-0 text-gray-light hover:text-white xl:static" target="_blank">Designed by Gemfisher.com</a>
            </div>
            
        </footer>:null
    )
}


export default Footer