/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

 import React, { useState } from 'react';
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from 'gatsby'
import Header from "./header"
import Footer from "./footer"
import "../style/common.css"

const Layout = (props) => {
  const [floatVisible,setFloatVisible] = useState(true)
  const pageQuery = useStaticQuery(graphql`
  query {
      allStrapiGlobal {
          edges {
              node {
                  salePhone,
                  sideVisible
              }
            }
      }
  }`)

  const node = pageQuery.allStrapiGlobal.edges[0].node

  return (
    <div className="flex flex-col min-h-screen">
        <Header headerColor={props.headerColor} isHome={props.footer}/>
        <main className={props.bgColor}>{props.children}</main>
        <Footer visible={props.footer}/>
        {
          node.sideVisible && floatVisible?
          <aside className="bg-black text-white pt-6 pb-4 px-3 fixed right-1 bottom-3 z-10 w-fit h-[95px]">
            <p>免费选型服务</p>
            <p className="text-3xl leading-snug">{node.salePhone}</p>
            <svg width="12" height="12" className="absolute right-3 top-2 cursor-pointer" onClick={()=>{setFloatVisible(!floatVisible)}}><path d="m10.8.2 1 1L1.2 11.8l-1-1L10.8.2Z" fill="currentColor"/><path d="m11.8 10.8-1 1L.2 1.2l1-1 10.6 10.6Z" fill="currentColor"/></svg>
          </aside>:null
        }
    </div>
  )
}


Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
