import React,{ useState,useEffect } from "react"
import tw from 'twin.macro'
import { useStaticQuery, graphql } from 'gatsby'



const Header = (props) => {
  const [headerColor,setHeaderColor] = useState(props.headerColor)
  const [visible, setVisible] = useState(false);
  const navFun = (e) => {
    setVisible(!visible)
    e.nativeEvent.stopImmediatePropagation();
  }
  useEffect(()=>{
    if(window){
      window.document.addEventListener('click', ()=>{
        setVisible(false)
      });
      window.addEventListener("scroll",()=>{
        if(props.headerColor.indexOf("bg-transparent") > -1){
          if(window.pageYOffset >= document.documentElement.clientHeight){
            setHeaderColor("bg-white text-black backdrop-filter backdrop-blur-lg")
          }else{
            setHeaderColor(props.headerColor)
          }
        }else{
          setHeaderColor(`${props.headerColor} backdrop-filter backdrop-blur-lg`)
        }
      })
    }
  },[])
  const styles = {
      button:(e)=>[
          tw`flex items-center px-[15px] py-1 rounded-full`,
          e.indexOf('text-white') > -1 && tw`hover:bg-white`,
          e.indexOf('text-black') > -1 && tw`hover:bg-black`,
          tw`hover:bg-opacity-10`
      ]
  }

  const pageQuery = useStaticQuery(graphql`
   query {
       allStrapiGlobal {
           edges {
               node {
                salePhone
               }
             }
       }
       allStrapiProductCategories {
          edges {
              node {
                title,
                url
              }
          }
      }
   }`)
   const node = pageQuery.allStrapiGlobal.edges[0].node
   const nodeLi = pageQuery.allStrapiProductCategories.edges
  return (
    <>
      <header className={`fixed top-0 left-1/2 w-full bg-opacity-90 transform -translate-x-1/2 z-10 ${props.isHome?headerColor:props.headerColor}`}>
        <div className="flex justify-between items-center container mx-auto">
          <div className="cursor-pointer" onClick={navFun}>
            <svg width="34" height="27" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m2 2.7 30-.4M2 14l30-.4M2 25l30-.4" stroke="currentColor" strokeWidth="3" strokeLinecap="square"/></svg>
          </div>
          <div className="max-w-[944px] w-full flex items-center justify-between xl:w-auto">
            <ul className="flex items-center ml-[-15px] xl:hidden">
              {
                nodeLi.map((n,i)=>{
                  if(i<5){
                    return <li key={i}><a href={n.node.url} css={styles.button(headerColor)}>{n.node.title}</a></li>
                  }
                })
              }
            </ul>
            <a href="/" className="flex items-center h-16 font-bold text-lg">
              <svg width="80" height="26" fill="currentColor" xmlns="http://www.w3.org/2000/svg"><path d="M40.062 26h2.706V11H48V8.188h-5.232V2h-2.706v6.188H37V11h3.062v15ZM53.062 26h2.706V11H61V8.188h-5.232V2h-2.706v6.188H50V11h3.062v15ZM26.5 11.777a5.729 5.729 0 0 1 5.723 5.723 5.729 5.729 0 0 1-5.723 5.723 5.73 5.73 0 0 1-5.723-5.723 5.729 5.729 0 0 1 5.723-5.723ZM26.5 26c4.687 0 8.5-3.813 8.5-8.5 0-4.686-3.813-8.5-8.5-8.5-4.686 0-8.5 3.814-8.5 8.5 0 4.687 3.814 8.5 8.5 8.5ZM71.5 11.777a5.729 5.729 0 0 1 5.722 5.723 5.73 5.73 0 0 1-5.722 5.723 5.73 5.73 0 0 1-5.722-5.723 5.729 5.729 0 0 1 5.722-5.723ZM71.5 26c4.687 0 8.5-3.813 8.5-8.5 0-4.686-3.813-8.5-8.5-8.5-4.687 0-8.5 3.814-8.5 8.5 0 4.687 3.813 8.5 8.5 8.5ZM9.344 23.084c-4.67.819-6.62-4.323-6.62-4.323L0 19.901S2.079 26 8.35 26c6.244 0 7.65-4.911 7.65-6.638v-.03c0-3.286-1.72-5.526-5.118-7.421-5.573-3.108-6.356-4.055-6.318-5.923.023-1.154 1.087-2.68 2.632-3.109.91-.254 3.29-.517 5.293 2.913l2.958-.67S13.604 0 8.422 0C6.385 0 4.71.651 3.445 1.936 2.212 3.188 1.678 4.579 1.678 6.314c0 1.168.398 2.282 1.226 3.444 1.5 2.106 6.403 4.7 6.403 4.7 3.257 1.68 3.73 3.617 3.73 4.976 0 1.685-1.552 3.275-3.693 3.65Z"/></svg>
            </a>
          </div>
        </div>
      </header>
      <nav onClick={(e)=>{e.nativeEvent.stopImmediatePropagation()}} className={`bg-black fixed left-0 top-0 z-[11] h-full w-[31.25%] sm:w-full transform transition duration-500 ease-in-out text-center xl:w-full xl:flex xl:pl-[10%] ${visible?'translate-x-0':'-translate-x-full'}`}>
        <svg width="25" height="25" className="absolute right-[78px] top-10 cursor-pointer sm:right-10 sm:top-10" onClick={navFun}><path d="m22.4.3 2.3 2.3-22.1 22-2.3-2.2L22.4.4Z" fill="#fff"/><path d="m24.7 22.4-2.3 2.3L.4 2.6 2.5.3l22 22.1Z" fill="#fff"/></svg>
        <div className="w-max h-full inline-flex flex-col text-left text-[#aaa]">
          <ul className="text-3xl mt-[13vh]">
            <li className="py-[1.38888vh]"><a className="hover:text-white text-3xl" href="/">首页</a></li>
            <li className="py-[1.38888vh]"><a className="hover:text-white text-3xl" href="/tech">核心技术</a></li>
            <li className="py-[1.38888vh]"><a className="hover:text-white text-3xl" href="/products">产品系列</a></li>
            <li className="py-[1.38888vh]"><a className="hover:text-white text-3xl" href="/media">媒体中心</a></li>
            <li className="py-[1.38888vh]"><a className="hover:text-white text-3xl" href="/about">速通品牌</a></li>
            <li className="py-[1.38888vh]"><a className="hover:text-white text-3xl" href="/contact">取得联系</a></li>
          </ul>
          <ul className="mb-[4.5vh] mt-auto">
            <li className="py-[0.46vh]"><a className="hover:text-white" href="/case/biomedical">案例应用</a></li>
            <li className="py-[0.46vh]"><a className="hover:text-white" href="/job">工作机会</a></li>
            <li className="py-[0.46vh]"><a className="hover:text-white" href="/download">资料下载</a></li>
          </ul>
          <p className="mb-[9.5vh]">销售 {node.salePhone}<br/><a href="/">www.cnsotto.com</a></p>
        </div>
      
      </nav>
    </>
  )
  
}

export default Header
